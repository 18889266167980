<template>
  <div>
    <b-row class="mx-auto mb-1">
      <b-col><statistic-card-horizontal icon="UsersIcon" color="danger" :statistic="dashboard_data.free_plan_count" statistic-title="FREE"/></b-col>
      <b-col><statistic-card-horizontal icon="UsersIcon" color="primary" :statistic="dashboard_data.basic_plan_count" statistic-title="BASIC"/></b-col>
      <b-col><statistic-card-horizontal icon="UsersIcon" color="secondary" :statistic="dashboard_data.standart_plan_count" statistic-title="STANDART"/></b-col>
      <b-col><statistic-card-horizontal icon="UsersIcon" color="warning" :statistic="dashboard_data.pro_plan_count" statistic-title="PRO"/></b-col>
      <b-col><statistic-card-horizontal icon="UsersIcon" color="success" :statistic="dashboard_data.enterprise_plan_count" statistic-title="ENTERPRISE"/></b-col>
    </b-row>
    <b-row class="mx-auto mb-1">
      <b-col><statistic-card-horizontal icon="UsersIcon" color="success" :statistic="dashboard_data.total_node" statistic-title="Toplam Birim"/></b-col>
      <b-col><statistic-card-horizontal icon="UsersIcon" color="success" :statistic="dashboard_data.total_qrcode" statistic-title="Toplam QR Kod"/></b-col>
      <b-col><statistic-card-horizontal icon="UsersIcon" color="success" :statistic="dashboard_data.total_feedback" statistic-title="Geri Bildirim"/></b-col>
      <b-col><statistic-card-horizontal icon="UsersIcon" color="success" :statistic="dashboard_data.total_scan_qr" statistic-title="QR Kod Taranma"/></b-col>
    </b-row>
    <b-row class="mx-auto">
      <b-col><statistic-card-horizontal icon="UsersIcon" :statistic="dashboard_data.total_company" :statistic-title="$t('dashboard.total_company')"/></b-col>
      <b-col><statistic-card-horizontal icon="UsersIcon" color="success" :statistic="dashboard_data.total_user" :statistic-title="$t('dashboard.users')"/></b-col>
      <b-col><statistic-card-horizontal icon="UsersIcon" color="success" :statistic="dashboard_data.active_accountstatus" statistic-title="Aktif Hesap"/></b-col>
      <b-col><statistic-card-horizontal icon="UsersIcon" color="success" :statistic="dashboard_data.suspended_accountstatus" statistic-title="Askıya Alınmış Hesap"/></b-col>
      <b-col><statistic-card-horizontal icon="UsersIcon" color="success" :statistic="dashboard_data.disabled_accountstatus" statistic-title="Kapatılmış Hesap"/></b-col>
    </b-row>

  </div>
</template>

<script>

import { BCol, BRow } from 'bootstrap-vue'
import StatisticCardHorizontal from '@/views/custom_app/client/dashboard/components/StatisticCardHorizontal.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    StatisticCardHorizontal, BCol, BRow,
  },
  data() {
    return {
      dashboard_data: {
        total_company: 0,
        total_user: 0,
        free_plan_count: 0,
        basic_plan_count: 0,
        standart_plan_count: 0,
        pro_plan_count: 0,
        enterprise_plan_count: 0,
        total_organization: 0,
        new_feedback_count: 0,
        open_feedback_count: 0,
        total_feedback_count: 0,
        total_qrcode_read_count: 0,
      },
    }
  },
  created() {
    this.getDashboardInfo()
  },
  methods: {
    getDashboardInfo() {
      useJwt.getDashboardInfo({})
        .then(response => {
          this.dashboard_data = response.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>
