<template>
  <div class="h-100">
    <b-card
      no-body
      class="h-100"
    >
      <b-row
        class="mx-50 mt-50"
        align-h="between"
        align-v="center"
      >
        <div>
          <date-range-picker
            ref="picker"
            v-model="orderRangeDate"
            :placeholder="$t('feedback_management.created_feedback_datetime')"
            style="min-width: 200px"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
          />
        </div>
        <div class="d-flex">
          <v-select
            id="i-plan"
            v-model="report_type"
            style="min-width: 300px"
            :options="filteredOptions"
            label="name"
            :clearable="false"
            :placeholder="$t('feedback_management.node_type')"
          >
            <template #option="{ title, icon }" />
          </v-select>
        </div>
      </b-row>
      <hr>
      <b-row>
        <b-col cols="12">
          <vue-apex-charts
            v-if="report_data"
            type="line"
            :options="chartOptions"
            :series="series"
          />

        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BBreadcrumbItem,
  BCard, BCol, BInputGroup, BInputGroupPrepend, BRow,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from '@/views/charts-and-maps/charts/apex-chart/apexChartData'
import vSelect from 'vue-select'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BInputGroupPrepend,
    vSelect,
    BInputGroup,
    VueApexCharts,
    BCard,
    BCol,
    BRow,
    BBreadcrumbItem,
    vSelect,
    DateRangePicker,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {},
  data() {
    return {
      series: [],
      chartOptions: {},
      report_data: null,
      apexChatData,
      report_type: { name: '', value: 'qrcode_scan_report' },
      report_type_options: [
        { name: this.$t('dashboard.qrcode_report'), value: 'qrcode_scan_report', hidden: !this.$ability.can('read', 'qrcode_management') },
        { name: this.$t('dashboard.feedback_report'), value: 'feedback_report', hidden: !this.$ability.can('read', 'feedback_management') },
      ],
      orderRangeDate: {
      },
    }
  },
  computed: {
    filteredOptions() {
      const vvv = this.report_type_options
        .filter(option => !option.hidden)
        .map(option => option)
      if (vvv.length >0) {
        this.report_type = vvv[0]
      }
      return vvv
    },
  },
  watch: {
    orderRangeDate: { handler() { this.getDashboardReportData() } },
    report_type: { handler() { this.getDashboardReportData() } },
  },
  created() {
    // Bugünün tarihini al
    const today = new Date()

    // 7 gün öncesinin tarihini hesapla
    const sevenDaysAgo = new Date()
    sevenDaysAgo.setDate(today.getDate() - 7)
    this.orderRangeDate.startDate = sevenDaysAgo
    this.orderRangeDate.endDate = today
    this.getDashboardReportData()
  },
  mounted() {
  },
  methods: {
    formatDate(date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // Aylar 0-11 arası olduğu için +1 ekliyoruz
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    getDashboardReportData() {
      useJwt.getDashboardReportData({
        start_date: this.formatDate(this.orderRangeDate.startDate),
        end_date: this.formatDate(this.orderRangeDate.endDate),
        report_type: this.report_type.value,
      })
        .then(response => {
          this.report_data = response.data
          this.series = [{
            name: this.report_type.name,
            data: this.report_data.x,
          }]
          this.chartOptions = {
            xaxis: {
              categories: this.report_data.y,
            },
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>
<style>

</style>
