<template>
  <div>
    <b-row
      class="mx-25"
      align-h="between"
      align-v="center"
    >
      <div class="d-flex">
        <v-select
          id="i-plan"
          v-model="organization_node_type"
          style="min-width: 200px"
          :options="organization_node_type_options"
          label="name"
          :clearable="false"
          :placeholder="$t('feedback_management.node_type')"
          multiple
        >
          <template #option="{ title, icon }" />
        </v-select>
      </div>
      <div>
        <b-input-group>
          <b-input-group-prepend is-text>
            <i class="pi pi-calendar" />
          </b-input-group-prepend>
          <date-range-picker
              ref="picker"
              v-model="orderRangeDate"
              :placeholder="$t('feedback_management.created_feedback_datetime')"
              style="min-width: 200px"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
          />
        </b-input-group>
      </div>
    </b-row>
    <b-row class="mt-25 mx-auto">
      <b-table
        responsive
        :items="table_data"
        :fields="tableColumns"
        primary-key="id"
        class="text-nowrap text-center"
        show-empty
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :empty-text="$t('core.no_records_found')"
        striped
        small
      >
        <!-- Column: feedback_status -->
        <template #cell(node.node_type_code)="data">
          <span
            v-for="item in organization_node_type_options"
            v-show="item.organization_type_code==data.item.node.node_type_code"
            :key="item.organization_type_code"
          >{{ item.name }}</span>
        </template>

      </b-table>
    </b-row>
    <b-row>
      <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
      </b-col>
      <!-- Pagination -->
      <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>

  </div>
</template>

<script>

import Board from '@salamander.be/vue-kanban-board'
import '@salamander.be/vue-kanban-board/dist/vue-kanban-board.css'
import {
  BBadge, BBreadcrumbItem,
  BButton, BButtonGroup,
  BCol,
  BDropdown,
  BDropdownItem, BFormCheckbox, BFormGroup,
  BFormInput, BInputGroup, BInputGroupPrepend,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import flatPickr from 'vue-flatpickr-component'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    BFormCheckbox,
    BBreadcrumbItem,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BFormInput,
    BPagination,
    vSelect,
    BButton,
    BTable,
    BRow,
    BCol,
    BDropdown,
    BBadge,
    BDropdownItem,
    BTab,
    BTabs,
    Board,
    BButtonGroup,
    flatPickr,
    DateRangePicker,
  },
  props: {
    selected_node: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      selected_row_name: null,
      selected_row_id: null,
      sub_node_list: false,
      perPageOptions: [25, 50, 100],
      sortBy: null,
      isSortDirDesc: null,
      table_data: null,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      orderRangeDate: {
        // startDate: new Date(),
        // endDate: new Date(),
      },
      organization_node_type: [],
      organization_node_type_options: [],
      tableColumns: [
        { key: 'node.name', label: this.$t('feedback_management.node_name'), sortable: true },
        { key: 'node.node_type_code', label: this.$t('feedback_management.node_type'), sortable: true },
        { key: 'created', label: this.$t('feedback_management.created_feedback_datetime'), sortable: true },
      ],
    }
  },
  watch: {
    sortBy: { handler() { this.getTableData() } },
    sub_node_list: { handler() { this.getTableData() } },
    organization_node_type: { handler() { this.getTableData() } },
    isSortDirDesc: { handler() { this.getTableData() } },
    orderRangeDate: { handler() { this.getTableData() } },
    currentPage: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.getOrganizationNodes()
    this.getTableData()
  },
  methods: {
    getOrganizationNodes() {
      useJwt.getOrganizationTypeList()
        .then(response => {
          this.organization_node_type_options = response.data.organization_type_list
          this.organization_node_type_options.sort((a, b) => ((a.name > b.name) ? 1 : -1))
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getTableData() {
      const filtred_organization_node_type = []
      this.organization_node_type.forEach(item => { filtred_organization_node_type.push(item.organization_type_code) })
      useJwt.getNodeReadHistoryList({
        // center_type: this.filter_center_type.value,
        page: this.currentPage,
        page_size: this.perPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        node__node_type_code__in: filtred_organization_node_type.join(', '),
        created__gte: this.orderRangeDate.startDate,
        created__lte: this.orderRangeDate.endDate,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>
<style lang="scss">
</style>
