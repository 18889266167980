<template>
  <div>
    <div v-if="user_tab=='superadmin'">
      <admin-dashboard />
    </div>
    <div v-if="user_tab=='client'">
      <client-dashboard />
    </div>
  </div>
</template>
<script>
import {getUserData} from "@/auth/utils";
import AdminDashboard from "@/views/custom_app/admin/dashboard/AdminDashboard.vue"
import ClientDashboard from "@/views/custom_app/client/dashboard/ClientDashboard.vue"
export default {
  components: {
    AdminDashboard,
    ClientDashboard
  },
  directives: {
  },
  props: {
  },
  data() {
    return {
      user_tab: null
    }
  },
  watch: {
  },
  created() {
    const userData = getUserData()
    if (userData.role == 'superadmin') {
      this.user_tab = 'superadmin'
    }else {
      this.user_tab = 'client'
    }
  },
  mounted() {},
  methods: {
  },
}
</script>
<style>

</style>
