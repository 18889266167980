<template>
  <div class="mx-xl-4">
    <!--  Sadece admin kullanıcılar görebilir   -->
    <b-row
        v-if="this.$ability.can('manage', 'customer_admin_management')"
        class="mb-1">
      <b-col cols="12">
        <b-card no-body class="bg-light-secondary">
          <b-row class="m-50 d-fle align-items-center">
            <b-col>
              <b-card-text class="mb-50">
                <i class="pi pi-qrcode" /> <b>{{ $t('dashboard.qrcode') }}: </b>{{dashboard_data.total_nodes_count}} / {{dashboard_data.qr_code_limit}}
              </b-card-text>
              <b-progress  :value="dashboard_data.total_nodes_count" :max="dashboard_data.qr_code_limit" height="6px"/>
            </b-col>
            <b-col>
              <b-card-text class="mb-50">
                <i class="pi pi-users" /> <b>{{ $t('dashboard.user') }}: </b>{{dashboard_data.total_user}} / {{dashboard_data.total_user_limit}}
              </b-card-text>
              <b-progress :value="dashboard_data.total_user" :max="dashboard_data.total_user_limit"  height="6px"/>
            </b-col>
            <b-col>
              <b-card-text class="mb-50">
                <i class="pi pi-sitemap" /> <b>{{ $t('dashboard.organization') }}: </b>{{dashboard_data.total_organization}} / {{dashboard_data.total_organization_limit}}
              </b-card-text>
              <b-progress :value="dashboard_data.total_organization" :max="dashboard_data.total_organization_limit"  height="6px"/>
            </b-col>
            <b-col>
              <b-card-text class="mb-50">
                <i class="pi pi-tags" /> <b>{{ $t('dashboard.group') }}: </b>{{dashboard_data.total_organization}} / {{dashboard_data.total_group_limit}}
              </b-card-text>
              <b-progress :value="dashboard_data.total_organization" :max="dashboard_data.total_group_limit"  height="6px"/>
            </b-col>
            <b-col>
              <b-card-text class="mb-50">
                <i class="pi pi-server" /> <b>{{ $t('dashboard.storage') }}: </b>{{dashboard_data.user_disk_quota}} / {{dashboard_data.max_disk_quota}} GB
              </b-card-text>
              <b-progress :value="dashboard_data.user_disk_quota" :max="dashboard_data.max_disk_quota"  height="6px"/>
            </b-col>
            <b-col>
              <b-card-text class="mb-50">
                <i class="pi pi-refresh" /> <b>{{ $t('dashboard.subscription') }}: </b>
                <span v-if="dashboard_data.plan_type=='free'">
                  oo
                </span>
                <span v-else>
                  {{dashboard_data.used_days}} / {{dashboard_data.periyot}}
                </span>
              </b-card-text>
              <b-progress v-if="dashboard_data.plan_type=='free'" value="0" max="100"  height="6px"/>
              <b-progress v-else :value="dashboard_data.used_days" :max="dashboard_data.periyot"  height="6px"/>
            </b-col>
            <b-col cols="1">
              <b-badge
                  @click="$router.push({ name: 'plan_management'})"
                  variant="warning"
                  size="sm"
              >
                {{ $t('dashboard.plan_upgrade') }}
              </b-badge>
            </b-col>
          </b-row>
      </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-intro="steps" data-step="1" cols="2"
          @click="$router.push({ name: 'organization-management'})"
          v-if="this.$ability.can('manage', 'customer_admin_management')">
        <statistic-card-horizontal
          icon="MapPinIcon"
          :statistic="dashboard_data.total_organization"
          :statistic-title="$t('dashboard.organizations')"
        />
      </b-col>
      <b-col v-intro="steps" data-step="2" cols="2"
          @click="$router.push({ name: 'user_management'})"
          v-if="this.$ability.can('manage', 'customer_admin_management')">
        <statistic-card-horizontal
          icon="UsersIcon"
          color="success"
          :statistic="dashboard_data.total_user"
          :statistic-title="$t('dashboard.users')"
        />
      </b-col>
      <b-col cols="2"
          @click="$router.push({ name: 'feedback-management'})"
          v-if="this.$ability.can('read', 'feedback_management')">
        <statistic-card-horizontal
          icon="ActivityIcon"
          color="danger"
          :statistic="dashboard_data.new_feedback_count"
          :statistic-title="$t('dashboard.new_feedback')"
        />
      </b-col>
      <b-col cols="2"
          @click="$router.push({ name: 'feedback-management'})"
          v-if="this.$ability.can('read', 'feedback_management')">
        <statistic-card-horizontal
          icon="MessageSquareIcon"
          color="warning"
          :statistic="dashboard_data.open_feedback_count"
          :statistic-title="$t('dashboard.open_feedback')"
        />
      </b-col>
      <b-col cols="2"
          @click="$router.push({ name: 'feedback-management'})"
          v-if="this.$ability.can('read', 'feedback_management')">
        <statistic-card-horizontal
          icon="DatabaseIcon"
          :statistic="dashboard_data.total_feedback_count"
          :statistic-title="$t('dashboard.total_feedback')"
        />
      </b-col>
      <b-col cols="2"
          v-if="this.$ability.can('read', 'qrcode_management')">
        <statistic-card-horizontal
          icon="SmartphoneIcon"
          color="warning"
          :statistic="dashboard_data.total_qrcode_read_count"
          :statistic-title="$t('dashboard.scanned_qrcode')"
        />
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
          v-if="this.$ability.can('read', 'qrcode_management')||this.$ability.can('read', 'feedback_management')"
          cols="6">
        <b-card no-body class="h-100">
          <b-tabs class="m-25">
            <b-tab
                v-if="this.$ability.can('read', 'qrcode_management')">
              <template #title>
                <i class="pi pi-qrcode" /> {{$t('dashboard.scanned_qrcode')}}
              </template>
              <b-row>
                <b-col cols="12">
                  <last-scanned-q-r-code />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab active
                   v-if="this.$ability.can('read', 'feedback_management')">
              <template #title><i class="pi pi-comments" />{{$t('dashboard.feedback')}}</template>
              <feedback-list-table view_mode="dashboard" />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
      <b-col
          v-if="this.$ability.can('read', 'qrcode_management')||this.$ability.can('read', 'feedback_management')"
          cols="6">
        <reports />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {BCol, BRow, BCard, BTab, BCardText, BTabs, BProgress, BButton, BBadge} from 'bootstrap-vue'
import StatisticCardHorizontal from "@/views/custom_app/client/dashboard/components/StatisticCardHorizontal.vue"
import useJwt from '@/auth/jwt/useJwt'
import LastScannedQRCode from "@/views/custom_app/client/dashboard/components/LastScannedQRCode.vue"
import FeedbackListTable from "@/views/custom_app/client/feedback_management/components/FeedbackListTable.vue";
import Reports from "@/views/custom_app/client/dashboard/components/Reports.vue";
import {getUserData} from "@/auth/utils";
import introJs from 'intro.js';
import 'intro.js/introjs.css';
export default {
  components: {
    BProgress,
    FeedbackListTable,
    BRow, StatisticCardHorizontal, BCol, BCard,
    BTabs,
    BBadge,
    BButton,
    BCardText,
    BTab,
    LastScannedQRCode,
    Reports,
  },
  data() {
    return {
      dashboard_data: {
        total_user: 0,
        total_organization: 0,
        new_feedback_count: 0,
        open_feedback_count: 0,
        total_feedback_count: 0,
        total_qrcode_read_count: 0,
      },
      steps: [
        {
          element: '[data-step="1"]',
          intro: 'Bu, ilk adımdır.',
        },
        {
          element: '[data-step="2"]',
          intro: 'Bu, ikinci adımdır.',
        }
      ]
    }
  },
  created() {
    this.getDashboardInfo()
  },
  mounted() {
  },
  methods: {
    startTour() {
      introJs().setOptions({ steps: this.steps }).start()
    },
    getDashboardInfo() {
      useJwt.getDashboardInfo({})
        .then(response => {
          this.dashboard_data = response.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },

  },
}
</script>
